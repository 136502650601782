import React from "react";
import Paragraphs from "components/Paragraphs";

export default function ParagraphGrid({ data, level }) {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${data.field_columns} gap-8`}
    >
      <Paragraphs paragraphs={data.field_paragraphs} level={level + 1} />
    </div>
  );
}
