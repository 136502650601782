import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";

export default function ParagraphCollapse({ data, loopIndex }) {
  return (
    <Disclosure
      as="div"
      defaultOpen={loopIndex === 0}
      className="border-b-2 border-black py-4"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-center w-full mx-auto text-xl font-semibold text-left">
            <span className="flex-1 text-3xl">{data.field_title}</span>
            <span className="bg-white rounded-full">
              {open ? (
                <>
                  <MinusCircleIcon className="h-10 w-10 text-secondary" />
                  <span className="sr-only">Replier</span>
                </>
              ) : (
                <>
                  <PlusCircleIcon className="h-10 w-10 text-primary-dark" />
                  <span className="sr-only">Déplier</span>
                </>
              )}
            </span>
          </Disclosure.Button>
          {open && (
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel
                className="py-4 prose bg-secondary/25 px-10 py-8 mt-2"
                dangerouslySetInnerHTML={{ __html: data.field_text.value }}
              />
            </Transition>
          )}
        </>
      )}
    </Disclosure>
  );
}
