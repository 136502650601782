import React from "react";
import HeroIcon from "components/HeroIcon";
import formatFileSize from "lib/formatFileSize";
import Link from "next/link";

export default function ParagraphFiles({ data }) {
  const iconForTypes = {
    "document-text": ["doc", "odf", "odt", "docx"],
    "document-report": ["ods", "xls", "xlsx"],
    photograph: ["png", "gif", "jpg", "jpeg", "odt"],
    archive: ["tar", "gz", "zip"],
    document: ["pdf"],
  };

  return (
    <>
      {data.field_title && (
        <h2 className="text-5xl font-semibold mb-14">{data.field_title}</h2>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {data.field_files.map((file) => {
          const [name, extension] = file.filename.split(".");
          const filesize = formatFileSize(file.filesize);
          let icon = "document";

          Object.keys(iconForTypes).forEach((iconName) => {
            if (iconForTypes[iconName].includes(extension)) {
              icon = iconName;
            }
          });

          return (
            <div key={file.id} className="group bg-white border-2 border-black">
              <Link
                href={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${file.uri.url}`}
                passHref
              >
                <a
                  className="flex items-center p-2 text-lg group-hover:text-secondary transition"
                  title={`Télécharger "${file.filename}" (${filesize})`}
                  target="blank"
                >
                  <HeroIcon
                    name={icon}
                    className="h-6 w-6 text-secondary mr-2"
                  />
                  <span className="flex-1">
                    {file.resourceIdObjMeta.description
                      ? file.resourceIdObjMeta.description
                      : name}
                  </span>
                </a>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}
