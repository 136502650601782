import React from "react";
import Paragraphs from "components/Paragraphs";

export default function ParagraphDisclosure({ data, level }) {
  return (
    <>
      {data.field_title && (
        <h2 className="text-5xl font-semibold mb-8">{data.field_title}</h2>
      )}
      <Paragraphs paragraphs={data.field_paragraphs} level={level + 1} />
    </>
  );
}
