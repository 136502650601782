import React from "react";

export default function ParagraphVideo({ data }) {
  function getYoutubeVideoId(url) {
    const regExp =
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regExp);
    return match && parseInt(match[1].length, 10) === 11 ? match[1] : false;
  }

  const youtubeId = getYoutubeVideoId(data.field_video_link);

  return (
    <>
      {data.field_title && (
        <h2 className="mb-8 max-w-3xl mx-auto text-5xl font-semibold">
          {data.field_title}
        </h2>
      )}
      <div className="max-w-3xl mx-auto">
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            src={
              youtubeId
                ? `https://www.youtube.com/embed/${youtubeId}`
                : data.field_video_link
            }
            title={data.field_title ? data.field_title : "Vidéo"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </>
  );
}
