import React from "react";
import Image from "next/image";

export default function ParagraphImage({ data }) {
  return (
    <div className="aspect-h-10 aspect-w-13">
      <figure>
        <Image
          src={`${data.field_image.image_style_uri.large}`}
          width={974}
          height={550}
          layout="fill"
          objectFit="cover"
          alt={data.field_image.resourceIdObjMeta.alt}
        />
        {data.field_image.resourceIdObjMeta.title && (
          <figcaption className="absolute bottom-0 left-0 px-4 py-2 bg-primary text-white">
            {data.field_image.resourceIdObjMeta.title}
          </figcaption>
        )}
      </figure>
    </div>
  );
}
