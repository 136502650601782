import React from "react";
import Body from "components/Body";

export default function ParagraphText({ data }) {
  return data.field_highlighted ? (
    <ParagraphTextHighlighted data={data} />
  ) : (
    <>
      {data.field_title && (
        <h2 className="mb-14 text-5xl font-semibold">{data.field_title}</h2>
      )}
      <div className="prose">
        <Body value={data.field_text.processed} />
      </div>
    </>
  );
}

function ParagraphTextHighlighted({ data }) {
  return (
    <div className="bg-secondary/70 px-8 py-12">
      {data.field_title && (
        <h2 className="mb-14 text-5xl font-semibold">{data.field_title}</h2>
      )}
      <div className="prose">
        <Body value={data.field_text.processed} />
      </div>
    </div>
  );
}
