import React from "react";
import Button from "components/Button";
import classNames from "lib/classNames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import Body from "components/Body";

export default function ParagraphCard({ data }) {
  const router = useRouter();

  const clickHandler = (event) => {
    if (data.field_link) {
      event.preventDefault();
      router.push(data.field_link.url);
    }
  };

  return (
    <div className="bg-white border-4 border-secondary" onClick={clickHandler}>
      {data.field_image?.image_style_uri.small && (
        <div className="w-2/3 mx-auto pt-12">
          <div className="aspect-w-13 aspect-h-10">
            <figure>
              <Image
                src={`${data.field_image.image_style_uri.small}`}
                width={462}
                height={260}
                layout="fill"
                objectFit="cover"
                alt={data.field_image.resourceIdObjMeta.alt}
              />
            </figure>
          </div>
        </div>
      )}
      <div className="py-8 px-6">
        <div className="text-xl font-semibold mb-8">{data.field_title}</div>
        <div className={classNames(data.field_link && "mb-8")}>
          <Body value={data.field_text.value} />
        </div>
        {data.field_link && <CardLink link={data.field_link} />}
      </div>
    </div>
  );
}

function CardLink({ link }) {
  const fromUri = link.uri.split(":").pop();
  return (
    <Link href={link.url !== "" ? link.url : fromUri} passHref>
      <Button as="a" theme="white" size="small" inverse>
        {link.title}
      </Button>
    </Link>
  );
}
