import React from "react";
import Image from "next/image";
import classNames from "lib/classNames";
import Body from "components/Body";

export default function ParagraphImageText({ data }) {
  return (
    <div>
      {data.field_title && (
        <h2 className="mb-8 text-5xl font-semibold">{data.field_title}</h2>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <figure className="relative">
          <Image
            className={classNames(
              data.field_reverse_order && "order-last",
              "col-start-1 col-span-1"
            )}
            src={data.field_image.image_style_uri.large}
            layout="responsive"
            width={310}
            height={174}
            objectFit="cover"
            alt={data.field_image.resourceIdObjMeta.alt}
          />
          {data.field_image.resourceIdObjMeta.title && (
            <figcaption className="absolute bottom-0 left-0 px-4 py-2 bg-white text-white">
              {data.field_image.resourceIdObjMeta.title}
            </figcaption>
          )}
        </figure>
        <div
          className={classNames(
            data.field_reverse_order && "order-first",
            "col-span-1 md:col-span-2"
          )}
        >
          <div className="prose max-w-none">
            <Body value={data.field_text.value} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ParagraphImageTextPrimary({ data, loopIndex }) {
  const even = !loopIndex % 2;
  return (
    <>
      <div
        className={classNames(
          even
            ? "z-20 bg-white xl:w-5/12 2xl:w-4/12 lg:right-1/2 lg:left-auto md:left-0 z-30"
            : "lg:left-1/3 2xl:-ml-5 xl:w-1/2 lg:w-2/3 xl:top-32 md:right-0 lg:top-48 bg-white/50 lg:pb-20 lg:pt-28 xl:mt-0 -mt-20 py-14 z-20 relative",
          "lg:absolute p-10"
        )}
      >
        {data.field_title && (
          <h2
            className={classNames(
              !even ? "lg:ml-64 text-5xl" : "text-4xl",
              "uppercase mb-8 font-important font-semibold"
            )}
          >
            {data.field_title}
          </h2>
        )}
        <div className="prose text-lg max-w-none">
          <Body value={data.field_text.value} />
        </div>
      </div>
      {even ? (
        <div className="lg:w-96 xl:bottom-20 2xl:left-32 xl:left-16 xl:bottom-8 lg:left-12 lg:bottom-8 lg:top-auto -top-4 lg:absolute w-2/3 mx-auto z-10 relative">
          <div className="aspect-h-8 aspect-w-13">
            <figure>
              <Image
                src={data.field_image.image_style_uri.large}
                layout="fill"
                width={data.field_image.resourceIdObjMeta.width}
                height={data.field_image.resourceIdObjMeta.height}
                objectFit="cover"
                alt={data.field_image.resourceIdObjMeta.alt}
              />
              {data.field_image.resourceIdObjMeta.title && (
                <figcaption className="absolute bottom-0 left-0 px-4 py-2 bg-white text-white">
                  {data.field_image.resourceIdObjMeta.title}
                </figcaption>
              )}
            </figure>
          </div>
        </div>
      ) : (
        <div className="lg:w-[460px] 2xl:right-48 xl:right-20 lg:-right-6 lg:-top-24 lg:right-0 -top-4 lg:absolute w-2/3 relative mx-auto lg:mt-0 z-30">
          <div className="aspect-h-7 aspect-w-11">
            <figure>
              <Image
                src={data.field_image.image_style_uri.large}
                layout="fill"
                width={data.field_image.resourceIdObjMeta.width}
                height={data.field_image.resourceIdObjMeta.height}
                objectFit="cover"
                alt={data.field_image.resourceIdObjMeta.alt}
              />
              {data.field_image.resourceIdObjMeta.title && (
                <figcaption className="absolute bottom-0 left-0 px-4 py-2 bg-white text-white">
                  {data.field_image.resourceIdObjMeta.title}
                </figcaption>
              )}
            </figure>
          </div>
        </div>
      )}
    </>
  );
}

export function ParagraphImageTextSecondary({ data }) {
  return (
    <div className="relative block bg-circle-waves bg-no-repeat circle-wrapper bg-bottom h-full lg:mx-4 w-full sm:w-2/3 lg:w-auto px-8 mx-auto">
      <div>
        <div className="relative pb-[75%]">
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-1/2 picture-slider">
            <div className="aspect-w-1 aspect-h-1 relative rounded-full overflow-hidden ">
              <figure>
                <Image
                  src={data.field_image.image_style_uri.medium}
                  layout="fill"
                  width={data.field_image.resourceIdObjMeta.width}
                  height={data.field_image.resourceIdObjMeta.height}
                  objectFit="cover"
                  alt={data.field_image.resourceIdObjMeta.alt}
                />
                {data.field_image.resourceIdObjMeta.title && (
                  <figcaption className="absolute bottom-0 left-0 px-4 py-2 bg-white text-white">
                    {data.field_image.resourceIdObjMeta.title}
                  </figcaption>
                )}
              </figure>
            </div>
          </div>
        </div>
        {data.field_title && (
          <h2 className="text-2xl uppercase pb-4 mt-8 text-center w-full">
            {data.field_title}
          </h2>
        )}
        <div className="prose text-lg max-w-none text-center w-full pb-12 md:pb-36 lg:pb-0 xl:pb-12 2xl:pb-28">
          <Body value={data.field_text.value} />
        </div>
      </div>
    </div>
  );
}
