import React from "react";
import ParagraphText from "components/Paragraphs/ParagraphText";
import ParagraphImage from "components/Paragraphs/ParagraphImage";
import ParagraphDisclosure from "components/Paragraphs/ParagraphDisclosure";
import ParagraphCollapse from "components/Paragraphs/ParagraphCollapse";
import ParagraphGrid from "components/Paragraphs/ParagraphGrid";
import classNames from "lib/classNames";
import ParagraphCard from "components/Paragraphs/ParagraphCard";
import ParagraphFiles from "components/Paragraphs/ParagraphFiles";
import ParagraphVideo from "components/Paragraphs/ParagraphVideo";
import PrevArrow from "components/Slider/PrevArrow";
import NextArrow from "components/Slider/NextArrow";
import ParagraphImageText, {
  ParagraphImageTextPrimary,
  ParagraphImageTextSecondary,
} from "components/Paragraphs/ParagraphImageText";
import Slider from "react-slick";

export default function Paragraphs({ paragraphs, level = 0, ...props }) {
  const components = {
    "paragraph--text": ParagraphText,
    "paragraph--image": ParagraphImage,
    "paragraph--disclosure": ParagraphDisclosure,
    "paragraph--collapse": ParagraphCollapse,
    "paragraph--grid": ParagraphGrid,
    "paragraph--card": ParagraphCard,
    "paragraph--files": ParagraphFiles,
    "paragraph--image_text": ParagraphImageText,
    "paragraph--video": ParagraphVideo,
  };

  return (
    <>
      {paragraphs.map((paragraph, index) => {
        if (Object.prototype.hasOwnProperty.call(components, paragraph.type)) {
          const ParagraphComponent = components[paragraph.type];

          return (
            <div
              key={paragraph.id}
              className={classNames(level === 0 && "mt-8")}
            >
              <ParagraphComponent
                data={paragraph}
                loopIndex={index}
                level={level}
                {...props}
              />
            </div>
          );
        }

        console.warn(
          `Unknown paragraph type "${paragraph.type}" at tree level ${level}.`
        );
        return null;
      })}
    </>
  );
}

export function ParagraphsPrimary({ paragraphs, ...props }) {
  return (
    <div className="w-full relative lg:h-176">
      {paragraphs.map((paragraph, index) => {
        return (
          <div key={paragraph.id} className="mt-8">
            <ParagraphImageTextPrimary
              data={paragraph}
              loopIndex={index}
              level={0}
              {...props}
            />
          </div>
        );
      })}
    </div>
  );
}

export function ParagraphsSecondary({ paragraphs, ...props }) {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {paragraphs.map((paragraph, index) => {
        return (
          <div key={paragraph.id} className="relative h-full">
            <ParagraphImageTextSecondary
              data={paragraph}
              loopIndex={index}
              level={0}
              {...props}
            />
          </div>
        );
      })}
    </Slider>
  );
}
